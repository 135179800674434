import React from "react";
import { useParams } from "react-router-dom";
import PrimaryNav from "../../components/PrimaryNav/PrimaryNav";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import SidebarNav from "../../components/SidebarNav/SidebarNav";
import { ROUTES } from "../../helpers/menus/routeConstants";
import { menuItems } from "../../helpers/menus/docsSidebarMenuItems";

import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const sectionNames = {
  "api-overview": "API Overview",
  'documentation': "Documentation",
  "get-started": "Getting Started",
  "change-history": "Change History",
  "help-and-support": "API Help & Support",
};

const apiName = {
  "dex-uploadapi": "DEX Upload API",
};
const DisableTryItOutPlugin = function() {
  return {
    statePlugins: {
      spec: {
        wrapSelectors: {
          allowTryItOutFor: () => () => false
        }
      }
    }
  }
}
const DisableAuthorizePlugin = function() {
  return {
    wrapComponents: {
      authorizeBtn: () => () => null
    }
  }
}
const SwaggerView = () => {
  const { slug, section } = useParams();
  let breadcrumbs = [
    { name: "Home", link: "/" },
    { name: "API Library", link: ROUTES["API Library"] },
    { name: apiName[slug], link: `/docs/${slug}/api-overview` },
    { name: sectionNames[section], link: `/docs/${slug}/documentation` },
    { name: "Swagger View" },
  ];

  const swaggerDoc = require(`../../api-docs/${slug}/openapi-tusd.yml`);
  return (
    <>
      <PrimaryNav />
      <Breadcrumbs crumbs={breadcrumbs} />
      <div className="grid-row grid-gap padding-x-5">
        <SidebarNav menuItems={menuItems} />
        <div className="tablet:grid-col-6 markdown">
          <SwaggerUI url={swaggerDoc} plugins={[DisableTryItOutPlugin, DisableAuthorizePlugin]}/>

        </div>
      </div>
    </>
  );
};

export default SwaggerView;
