import React, { useState } from "react";
import ServiceNowQuestion from "../ServiceNow/ServiceNowQuestion";
import { contactUsRequest } from "../../api/api";
import {
  yesNo,
  priority,
  apis,
  environments,
  cdcServices,
} from "../../helpers/dropdowns/selections";

const SupportTicketForm = ({ setTicketNumber }) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    sams_id: "",
    program: "",
    organization: "",
    orgnaization_url: "",
    intermediary: "",
    issue_name: "",
    priority: "",
    service: "",
    api: "",
    description: "",
    environment: "",
    additional_info: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const isFormValid = () => {
    const { name, email, priority, service, api, description, issue_name } =
      state;

    return !(
      name &&
      email &&
      issue_name &&
      priority &&
      service &&
      api &&
      description
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (state.hasSams === "No") {
      state.samsEmail = "";
    }
    try {
      const data = await contactUsRequest(state);
      setTicketNumber(data.request_number);
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  return (
    <>
      <div className="tablet:padding-x-5 padding-y-3 margin-x-5 ">
        <h1 className="page-title">Submit a Support Ticket</h1>

        <div className="grid-col-12 tablet:grid-col-6 service-now-question-block desktop:grid-col-6 support">
          <p className="service-now-ticket-description support">
            Use the form below to help us get you the support you need. We
            capture all the eseential information, but in some cases the Support
            Team may need to contact you for additional details.
          </p>
        </div>
      </div>
      <form className="bg-light-blue" onSubmit={handleSubmit}>
        <div className="tablet:padding-x-6 padding-y-4 margin-x-6 ">
        <h2 className="text-bold text-base-darkest">Contact Information</h2>
        <p>
          Required fields all are marked with an asterisk(
          <span className="service-now-asterisk">*</span>)
        </p>
        <div className="grid-row grid-gap">
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block support margin-bottom-6 desktop:grid-col-4">
            <ServiceNowQuestion
              question="Name"
              details="Provide your full name."
              optionalClass="support"
              requiredQuestion={true}
              label="name"
            />
            <input
              type="text"
              required
              id="name"
              aria-label="Enter your Name"
              name="name"
              value={state.name}
              onChange={handleChange}
              className="usa-input"
            />
          </div>
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block margin-bottom-6 desktop:grid-col-4 support">
            <ServiceNowQuestion
              question="Email"
              details=""
              requiredQuestion={true}
              optionalClass="support"
              label="email"
            />
            <input
              type="text"
              required
              id="name"
              aria-label="Enter your email"
              name="email"
              value={state.email}
              onChange={handleChange}
              className="usa-input"
            />
          </div>
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block margin-bottom-6 desktop:grid-col-4 support">
            <ServiceNowQuestion
              question="SAMs ID"
              details=""
              optionalClass="support"
              requiredQuestion={false}
              label="name"
            />
            <input
              type="text"
              id="name"
              aria-label="Enter your SAMs ID"
              name="sams_id"
              value={state.sams_id}
              onChange={handleChange}
              className="usa-input"
            />
          </div>
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block margin-bottom-6 desktop:grid-col-4">
            <ServiceNowQuestion
              question="Program"
              details=""
              requiredQuestion={false}
              optionalClass="support"
              label="program"
            />
            <input
              type="text"
              id="program"
              aria-label="Enter your program"
              name="program"
              value={state.program}
              onChange={handleChange}
              className="usa-input"
            />
          </div>
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block margin-bottom-6 desktop:grid-col-4">
            <ServiceNowQuestion
              question="Organization"
              details=""
              requiredQuestion={false}
              optionalClass="support"
              label="organization"
            />
            <input
              type="text"
              id="organization"
              aria-label="Enter your organization"
              name="organization"
              value={state.organization}
              onChange={handleChange}
              className="usa-input"
            />
          </div>
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block margin-bottom-6 desktop:grid-col-4">
            <ServiceNowQuestion
              question="Organization URL"
              details=""
              requiredQuestion={false}
              optionalClass="support"
              label="orgnaization_url"
            />
            <input
              type="text"
              id="orgnaization_url"
              aria-label="Enter your Organizations URL"
              name="orgnaization_url"
              value={state.orgnaization_url}
              onChange={handleChange}
              className="usa-input"
            />
          </div>
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block margin-bottom-6 desktop:grid-col-4">
            <ServiceNowQuestion
              question="Intermediary"
              details="Are you acting on behalf of another organization?"
              requiredQuestion={false}
              optionalClass="support"
              label="intermediary"
            />
            <select
              className="usa-select "
              onChange={handleChange}
              id="intermediary"
              name="intermediary"
              aria-label="Are you part of intermediary?"
              defaultValue=""
            >
              <option value="" disabled>
                - Select -
              </option>
              {yesNo.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <h2 className="text-bold text-base-darkest">Service Details</h2>
        <p>
          Required fields all are marked with an asterisk(
          <span className="service-now-asterisk">*</span>)
        </p>
        <div className="grid-col-12 tablet:grid-col-12 service-now-question-block margin-bottom-12 desktop:grid-col-12">
          <ServiceNowQuestion
            question="Title/Subject"
            optionalClass="support"
            details="Provide a brief summary of the issue."
            requiredQuestion={true}
            label="issue_name"
          />
          <input
            type="text"
            required
            id="issue_name"
            aria-label="Issue Summary"
            name="issue_name"
            value={state.issue_name}
            onChange={handleChange}
            className="usa-input single-item"
          />
        </div>
        <div className="grid-row grid-gap">
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block margin-bottom-6 desktop:grid-col-4">
            <ServiceNowQuestion
              question="Priority"
              optionalClass="support"
              details="Select the urgency level of the issue."
              requiredQuestion={true}
              label="priority"
            />
            <select
              className="usa-select "
              onChange={handleChange}
              id="priority"
              name="priority"
              aria-label="Select priority level."
              required
              defaultValue=""
            >
              <option value="" disabled>
                - Select -
              </option>
              {priority.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block margin-bottom-6 desktop:grid-col-4">
            <ServiceNowQuestion
              question="Service"
              optionalClass="support"
              details="Select the service with the issue."
              requiredQuestion={true}
              label="service"
            />
            <select
              className="usa-select "
              onChange={handleChange}
              id="service"
              name="service"
              aria-label="What service is having the issue"
              required
              defaultValue=""
            >
              <option value="" disabled>
                - Select -
              </option>
              {cdcServices.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="grid-col-12 tablet:grid-col-4 service-now-question-block margin-bottom-6 desktop:grid-col-4">
            <ServiceNowQuestion
              question="API"
              optionalClass="support"
              details="Select which API is causing the issue."
              requiredQuestion={true}
              label="api"
            />
            <select
              className="usa-select"
              onChange={handleChange}
              id="api"
              name="api"
              aria-label="Select an api"
              required
              defaultValue=""
            >
              <option value="" disabled>
                - Select -
              </option>
              {apis.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="grid-col-12 tablet:grid-col-12 service-now-question-block margin-bottom-12 desktop:grid-col-12">
            <ServiceNowQuestion
              question="Description"
              optionalClass="support"
              details="Give a detailed description of the problem encountered or the service being requested."
              requiredQuestion={true}
              label="description"
            />
            <textarea
              className="usa-textarea single-item"
              type="text"
              name="description"
              id="description"
              required
              aria-label="description details"
              rows="6"
              value={state.description}
              onChange={handleChange}
            />
          </div>

          <div className="grid-col-4 tablet:grid-col-4 margin-bottom-4 desktop:grid-col-4">
            <ServiceNowQuestion
              question="Environment"
              optionalClass="support"
              details="Where are you encountering the issue?"
              requiredQuestion={false}
              label="environment"
            />
            <select
              className="usa-select"
              onChange={handleChange}
              id="environment"
              name="environment"
              aria-label="Select an environment"
              defaultValue=""
            >
              <option value="" disabled>
                - Select -
              </option>
              {environments.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
            <ServiceNowQuestion
              question="Attachments"
              optionalClass="support"
              details="Select one or more relevant files or screenshots."
              requiredQuestion={false}
              label="attachments"
            />
            <input
              id="file-input-multiple"
              className="usa-file-input"
              type="file"
              name="attachments"
              aria-label="file input selection"
              multiple="multiple"
            />
          </div>
          <div className="grid-col-8 tablet:grid-col-8 margin-bottom-4 desktop:grid-col-8">
            <ServiceNowQuestion
              question="Additional Information"
              optionalClass="support"
              details="Provide any other relevant details or context that could help the support team understand the issue or the service request."
              requiredQuestion={false}
              label="additional_info"
            />
            <textarea
              className="usa-textarea single-item"
              type="text"
              name="additional_info"
              id="additional_info"
              aria-label="description details"
              rows="6"
              value={state.additional_info}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <button
              className="usa-button usa-button--accent service-now-button"
              type="submit"
              disabled={!isFormValid() ? null : "disabled"}
            >
              Submit
            </button>
          </div>
        </div>
        </div>
      </form>
    </>
  );
};

export default SupportTicketForm;
