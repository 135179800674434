import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import AboutUs from './pages/AboutUs/AboutUs';
import './components/components.css'
import APIDocsTemplate from './pages/APIDocsTemplate/APIDocsTemplate';
import Banner from './components/Banner/Banner'
import BecomeAPIProvider from './pages/BecomeAPIProvider/BecomeAPIProvider';
import Faq from './pages/FAQ/Faq';
import Footer from "./components/Footer/Footer";
import HelpAndSupport from './pages/HelpAndSupport/HelpAndSupport';
import Homepage from './pages/Homepage/Homepage';
import SupportTicket from './pages/SupportTicket/SupportTicket';
import APILibrary from './pages/APILibrary/APILibrary';
import Page404 from './pages/ErrorPages/Page404';
import SwaggerView from './pages/SwaggerViewTemplate/SwaggerView';
function App() {
  return (
    <Router>
        <Banner />
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/docs/:slug/:section/*" element={<APIDocsTemplate />} />
            <Route path="/docs/:slug/:section/swagger" element={<SwaggerView />} />
            <Route path="/apis" element={<APILibrary />} />
            <Route path="/help" element={<HelpAndSupport />} />
            <Route path="/help/become-provider" element={<BecomeAPIProvider />} />
            <Route path="/support-ticket/submit-support-ticket" element={<SupportTicket/>}/>
            <Route path="/help/faq" element={<Faq />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
      <Footer />
    </Router>
  );
} 

export default App;
